import { getTenancyDefaultUrlQuery } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { useDefaultTenancyFilterBookmarkQuery } from "./queries/useDefaultTenancyFilterBookmarkQuery";
import { PERMISSIONS } from "~/composables/usePermissions";
import { AssetManagementPropertySubType } from "~/graphql/generated/graphql";
export var useUrlTabsProperty = function useUrlTabsProperty(property) {
  var t = useTranslator();
  var isProperty = computed(function () {
    var _property$value;
    return ((_property$value = property.value) === null || _property$value === void 0 ? void 0 : _property$value.subType) === AssetManagementPropertySubType.Property;
  });
  var hasSalesPermission = useHasPermissions(PERMISSIONS.PORTFOLIO_SALES_READ_ORG);
  var hasValuationsPermission = useHasPermissions(PERMISSIONS.PORTFOLIO_VALUATIONS_READ_ORG);
  var defaultFilterQuery = useDefaultTenancyFilterBookmarkQuery(computed(function () {
    return true;
  }));
  return computed(function () {
    var _defaultFilterQuery$r;
    return [{
      key: "overview",
      label: t("PORTFOLIO_PROPERTY_OVERVIEW"),
      default: true,
      disabled: !isProperty.value
    }, {
      key: "units",
      label: t("PORTFOLIO_PROPERTY_UNITS"),
      query: getTenancyDefaultUrlQuery((_defaultFilterQuery$r = defaultFilterQuery.result.value) === null || _defaultFilterQuery$r === void 0 ? void 0 : _defaultFilterQuery$r.id),
      disabled: !isProperty.value
    }, {
      key: "opexes",
      label: t("PORTFOLIO_PROPERTY_OPEX"),
      disabled: !isProperty.value
    }, {
      key: "capexes",
      label: t("PORTFOLIO_PROPERTY_CAPEX"),
      disabled: !isProperty.value
    }, {
      key: "sales",
      label: t("PORTFOLIO_PROPERTY_SALES"),
      disabled: !hasSalesPermission.result.value || !isProperty.value
    }, {
      key: "balance",
      label: t("PORTFOLIO_PROPERTY_BALANCE"),
      default: !isProperty.value
    }, {
      key: "valuations",
      label: t("PORTFOLIO_PROPERTY_VALUATIONS"),
      disabled: !hasValuationsPermission.result.value || !isProperty.value
    }, {
      key: "actionplans",
      label: t("PORTFOLIO_PROPERTY_ACTION_PLANS"),
      disabled: !isProperty.value
    }];
  });
};